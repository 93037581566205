<template>
  <div class="page" style="background-color: #fff;">
    <Nav></Nav>
    <main class="wrap">
      <div class="main">
        <div class="goods">
          <div class="l">
            <a-carousel arrows dots-class="slick-dots slick-thumb">
              <a slot="customPaging" slot-scope="props">
                <img :src="getImgUrl(props.i)" />
              </a>
              <div v-for="(item, index) in goods.pictures" :key="index">
                <img :src="item.fileUrl" />
              </div>
            </a-carousel>
          </div>
          <div class="r">
            <div class="title">{{ goods.name }}</div>
            <div class="info" style="border-bottom:none;">
              <div class="label">价格：</div>
              <div class="price">￥{{ selectspec.id > 0 ? selectspec.price : goods.price }}</div>
            </div>
            <div class="info" style="border-top:none;">
              <div class="label">市场价：</div>
              <div class="marketprice">￥{{ (selectspec.id > 0 ? selectspec.marketPrice : goods.marketPrice) }}</div>
            </div>
            <div class="info" style="height: 100px;">
              <div class="label">规格：</div>
              <div class="spec">
                <a href="jaascript:;" @click="specselect(item)" v-for="(item, index) in goods.specs" :key="index">
                  <div class="specitem" :class="selectspec.id == item.id ? 'act' : ''">
                    {{ item.name }}
                  </div>
                </a>
              </div>
            </div>
            <div class="info">
              <div class="btn">
                <a-button type="default" size="large" @click="addCart">
                  加入购物车
                </a-button>
                <a-button type="primary" size="large" @click="buy">
                  立即购买
                </a-button>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="title">详情</div>
          <div class="des" v-html="goods.description"></div>
        </div>
      </div>
    </main>
    <foot></foot>
  </div>
</template>

<script>

import Nav from "@/components/Nav.vue";
import foot from "@/components/foot.vue";

export default {
  name: "Home",
  components: {
    Nav,
    foot,
  },
  data() {
    return {
      id: 0,
      goods: {},
      selectspec: {
        id: 0,
        name: '',
      },
    };
  },
  created() {
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1];
    let getqys = getqyinfo.split("&");
    let obj = {}; //创建空对象，接收截取的参数
    for (let i = 0; i < getqys.length; i++) {
      let item = getqys[i].split("=");
      let key = item[0];
      let value = item[1];
      obj[key] = value;
    }
    this.id = obj.id;

    this.loadData();
  },
  methods: {
    //请求数据
    loadData() {
      //商品详情
      this.api.getproductdetail({ id: this.id }).then((res) => {
        this.goods = res;
      }).catch((err) => {
        console.log(err);
      });
    },
    //展示图片
    getImgUrl(i) {
      return this.goods.pictures[i].fileUrl;
    },
    //选择规格
    specselect(item) {
      this.selectspec = item;
    },
    //加入购物车
    addCart() {
      if (this.goods.specs.length > 0 && this.selectspec.id == 0) {
        this.$message.error('请选择规格！');
        return;
      }

      this.api.addCartProduct({
        id: this.goods.id, specId: this.selectspec.id,
        specName: this.selectspec.name
      }).then(() => {
        this.$message.info('添加成功！');
      }).catch((err) => {
        console.log(err);
      });
    },
    //立即购买
    buy() {
      if (this.goods.specs.length > 0 && this.selectspec.id == 0) {
        this.$message.error('请选择规格！');
        return;
      }


      this.goods.count = 1;
      var product = [];
      var goods = {};
      goods.id = this.goods.id;
      goods.code = this.goods.code;
      goods.title = this.goods.name;
      goods.price = this.selectspec.id > 0 ? this.selectspec.price : this.goods.price;
      goods.originPrice = this.selectspec.id > 0 ? this.selectspec.marketPrice : this.goods.marketPrice;
      goods.count = this.goods.count;
      goods.imgSrc = this.goods.pictures[0].fileUrl;
      goods.specId = this.selectspec.id;
      goods.specName = this.selectspec.name;
      product.push(goods);
      let order = {};
      order.product = product;
      order.remark = "";
      this.storage.setItem("order", order);
      this.$router.push("/buy");
    },
  },
};
</script>

<style scoped>
.page {
  background-color: #fff !important;
}

.ant-carousel>>>.slick-dots {
  height: auto;
}

.ant-carousel>>>.slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}

.ant-carousel>>>.slick-thumb {
  top: 360px;
  bottom: -45px;
  text-align: left;
}

.ant-carousel>>>.slick-thumb li {
  width: 78px;
  height: 78px;
}

.ant-carousel>>>.slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}

.ant-carousel>>>.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}

.main .goods {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 20px;
}

.main .goods .l {
  width: 420px;
  border: solid 1px #e8e8e8;
}

.main .goods .r {
  width: 100%;
  padding-left: 40px;
}

.main .goods .r .title {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 20px;
  position: relative;
}

.main .goods .r .info {
  display: flex;
  background-color: #f5f3ef;
  border-top: 1px dotted #dedede;
  border-bottom: 1px dotted #dedede;
  padding: 0 0 13px;
  line-height: 45px;
  font-size: 13px;
  position: relative;
}

.main .goods .r .info .label {
  width: 55px;
  font-size: 13px;
  float: left;
  color: #666;
  margin-left: 10px;
}

.main .goods .r .info .price {
  font-size: 28px;
  line-height: 30px;
  margin-left: 2px;
  color: #d7282d;
  height: 45px;
  margin-top: 10px;
  margin-bottom: 5px;
  overflow: hidden;
}

.main .goods .r .info .marketprice {
  font-size: 28px;
  line-height: 30px;
  margin-left: 2px;
  color: #aaa;
  text-decoration: line-through;
  height: 45px;
  margin-top: 10px;
  margin-bottom: 5px;
  overflow: hidden;
}

.main .goods .r .info .spec {
  display: flex;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
}

.main .goods .r .info .spec .specitem {
  margin-top: 10px;
  margin-left: 10px;
  border: 1px solid #ddd;
  padding-left: 30px;
  padding-right: 30px;
  height: 30px;
  line-height: 30px;
  color: #666;
}

.main .goods .r .info .spec .act {
  border: 1px solid #dc1e32;
  color: #dc1e32;
}


.main .goods .r .info .btn {
  margin-left: 12px;
  margin-top: 30px;
  font-size: 0;
  -webkit-text-size-adjust: none;
}

.main .goods .r .info button {
  margin-right: 10px;
}

.main .content {
  min-height: 800px;
  padding-top: 200px;
  overflow: hidden;
}

.main .content .title {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  background-color: #f5f5f5;
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  margin-bottom: 20px;
}

.main .content .des img {
  width: 100%;
}
</style>